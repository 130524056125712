import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import apiClient from '../utils/api';

const GuestList = () => {
  // State to hold the guest data
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading state

  // Effect to fetch data from the API
  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const response = await apiClient.get('guests/confirmation')
        setGuests(response.data);
      } catch (error) {
        console.error('Error fetching guests data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGuests();
  }, []);

  // Transform data for DataGrid
  const rows = guests.map((guest) => {
    const confirmation = guest.confirmations[0] || {};
    return {
      id: guest.id,
      fullName: `${guest.name} ${guest.surname}`,
      confirmed: guest.confirmations.length > 0 ? 'Confirmed' : 'Not Confirmed', // Check for confirmations
      musicSuggestion: confirmation.musicSuggestion || 'N/A',
      date: confirmation.createdAt ? format(new Date(confirmation.createdAt), 'dd/MM/yyyy') : 'N/A',
      phone: confirmation.phone || 'N/A',
    };
  });

  // Define columns
  const columns = [
    { field: 'fullName', headerName: 'Nome', width: 300 },
    { field: 'confirmed', headerName: 'RSVP', width: 150 }, // New column for confirmed status
    { field: 'musicSuggestion', headerName: 'Sugestão de música', width: 300 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
  ];

  // Define default sorting
  const sortModel = [
    {
      field: 'confirmed',
      sort: 'asc', // 'asc' sorts alphabetically so 'Confirmed' will appear before 'Not Confirmed'
    },
  ];

  return (
    <Box sx={{ height: 800, width: '100%' }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={50}
          sortModel={sortModel}
        />
      )}
    </Box>
  );
};

export default GuestList;
