import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const AboutUs = () => {

    return <Box>
        {/* Background Image */}
        <Box
            sx={{
                position: 'absolute',
                top: {
                    xs: '80px', // Small devices
                    sm: '130px', // Medium devices
                },
                left: {
                    xs: '0px', // Small devices
                    sm: '40px', // Medium devices   
                },
                width: { xs: '80px', sm: '150px', md: '100' }, // Responsive size
                height: { xs: '80px', sm: '150px', md: '100px' }, // Responsive size
                backgroundImage: 'url("/images/Aspas.png")', // Replace with your image URL
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left top',
                opacity: 0.3, // Adjust the opacity
                zIndex: 1, // Ensure the image is behind the content
            }}
        />

        {/* Text Section */}
        <Typography variant="h5" className='moderline' gutterBottom>
            Nossa História
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem', marginTop: '2rem' }}>
            <Typography variant="body1" gutterBottom>
                <p>Alguns vão dizer que foi só coincidência.</p>
                <p>Outros que era o nosso destino.</p>
                <p>
                    Eu prefiro dizer que foi a mão de Deus que fez uma menina recém formada em Geografia sentir uma vontade incontrolável de ir trabalhar em BH e topar qualquer emprego que aparecesse na sua frente, até mesmo um de Corretora de imóveis autônoma, sem fixo, sem garantias e sem nenhuma ajuda de custo, lá ela encontrou um menino de Contagem, que pedia tanto a Deus para encontrar a pessoa certo e tomar um rumo na vida!
                    E logo no primeiro olhar os dois sentiram que a partir daquele momento em diante, um seria do outro e a única coisa que importava era ficarem juntos.
                </p>

                <p>
                    Graças a Deus o improvável aconteceu e no lugar menos propício o amor nasceu, tudo tão rápido e tão espontâneo, deram o primeiro beijo e logo sem nem perceber já estavam morando juntos, conquistando tudo o que sempre sonharam e o mais importante, colecionando momentos únicos de carinho, superação e companheirismo.
                </p>

                <p>
                    Se tem um casal que nasceu um para o outro, esse casal se chama Carlos e Carlota!
                </p>
            </Typography>
        </Box>

        {/* Images Section */}
        <Grid container spacing={1} sx={{ marginTop: '20px', backgroundColor: '#fff', borderRadius: '2%', padding: '1rem 2rem' }}>
            <Grid item xs={12} md={4}>
                <Box
                    component="img"
                    src="/images/4.jpeg"
                    alt="Image 1"
                    sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Box
                    component="img"
                    src="/images/5.jpeg"
                    alt="Image 2"
                    sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Box
                    component="img"
                    src="/images/6.jpeg"
                    alt="Image 3"
                    sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                />
            </Grid>
        </Grid>
    </Box>;
};

export default AboutUs;