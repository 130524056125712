import { Box, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Slider from 'react-slick';
import React, { useState } from 'react';
import ModalContent from '../ModalContent/ModalContent';
import AboutUs from '../ModalContent/AboutUs';
import GiftListModal from '../ModalContent/GiftListModal';
import RSVPModal from '../ModalContent/RSVPModal';
import LocationModal from '../ModalContent/LocationModal';
import MakingOfModal from '../ModalContent/MakingOfModal';
import BestMenModal from '../ModalContent/BestMenModal';
import LeaveANote from '../ModalContent/LeaveANote';
import RingIcon from '../Icons/RingIcon';
import CalendarIcon from '../Icons/CalendarIcon';
import LocatioIcon from '../Icons/LocationIcon';
import GiftIcon from '../Icons/GiftIcon';
import NoteIcon from '../Icons/NoteIcon';
import PolaroidIcon from '../Icons/PolaroidIcon';
import MailIcon from '../Icons/MailIcon';

const IconSlider = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleOpenModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const IconItem = ({ icon, modalContent }) => {
        return (
            <Box
                onClick={() => handleOpenModal(modalContent)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'auto',
                    width: 'auto',
                    cursor: 'pointer',
                }}
            >
                {
                    React.cloneElement(icon, {
                        height: {
                            xs: 100,  // Smaller height on extra small devices
                            sm: 100,  // Slightly larger on small devices
                            md: 100, // Medium size on medium devices
                            lg: 150, // Larger size on large devices
                            xl: 200  // Largest size on extra large devices
                        }[window.innerWidth < 600 ? 'xs' : window.innerWidth < 960 ? 'sm' : window.innerWidth < 1280 ? 'md' : window.innerWidth < 1920 ? 'lg' : 'xl'],  // Get the right size based on the screen width

                        width: {
                            xs: 100,  // Smaller width on extra small devices
                            sm: 100,  // Slightly larger on small devices
                            md: 100, // Medium size on medium devices
                            lg: 150, // Larger size on large devices
                            xl: 200  // Largest size on extra large devices
                        }[window.innerWidth < 600 ? 'xs' : window.innerWidth < 960 ? 'sm' : window.innerWidth < 1280 ? 'md' : window.innerWidth < 1920 ? 'lg' : 'xl'],  // Get the right size based on the screen width
                    })
                }
            </Box>
        );
    }

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '-20px',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    display: 'block', // Always display arrows
                }}
            >
                <ArrowForward />
            </IconButton>
        );
    }

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '-20px',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    display: 'block', // Always display arrows
                }}
            >
                <ArrowBack />
            </IconButton>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Default to 4 icons per slide
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3, // Show 3 icons on medium-large screens
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2, // Show 2 icons on medium screens
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2, // Show 2 icons on small screens
                },
            },
        ],
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Slider {...settings}>
                <IconItem icon={<RingIcon />} modalContent={<AboutUs />} />
                <IconItem icon={<GiftIcon />} modalContent={<GiftListModal />} />
                <IconItem icon={<CalendarIcon />} modalContent={<RSVPModal onSubmit={handleCloseModal} />} />
                <IconItem icon={<LocatioIcon />} modalContent={<LocationModal />} />
                <IconItem icon={<NoteIcon />} modalContent={<MakingOfModal />} />
                <IconItem icon={<PolaroidIcon />} modalContent={<BestMenModal />} />
                <IconItem icon={<MailIcon />} modalContent={<LeaveANote onSubmit={handleCloseModal} />} />
            </Slider>

            {/* Render ModalContent outside the slider */}
            <ModalContent
                open={modalOpen}
                onClose={handleCloseModal}
                content={modalContent}
            />
        </Box>
    );
}

export default IconSlider;
