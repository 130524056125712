import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const now = new Date();
        const difference = targetDate - now;

        if (difference > 0) {
            const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30)).toString().padStart(2, '0');
            const days = Math.floor((difference / (1000 * 60 * 60 * 24)) % 30).toString().padStart(2, '0');
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
            const minutes = Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0');
            const seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0');

            return { months, days, hours, minutes, seconds };
        } else {
            return { months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: { xs: 2, sm: 3, md: 5 }, flexWrap: '' }}>
            <Box className="countdownItem countdownItemGreen">
                <Typography
                    className='countdownItemNumber'
                    variant="h4"
                >
                    {timeLeft.months}
                </Typography>
                <Typography
                    className='countdownItemText'
                >
                    Meses
                </Typography>
            </Box>
            <Box className="countdownItem">
                <Typography
                    className='countdownItemNumber'
                    variant="h4"
                >
                    {timeLeft.days}
                </Typography>
                <Typography
                    className='countdownItemText'
                >
                    Dias
                </Typography>
            </Box>
            <Box className="countdownItem countdownItemGreen">
                <Typography
                    className='countdownItemNumber'
                    variant="h4"
                >
                    {timeLeft.hours}
                </Typography>
                <Typography
                    className='countdownItemText'
                >
                    Horas
                </Typography>
            </Box>
            <Box className="countdownItem">
                <Typography
                    className='countdownItemNumber'
                    variant="h4"
                >
                    {timeLeft.minutes}
                </Typography>
                <Typography
                    className='countdownItemText'
                >
                    Minutos
                </Typography>
            </Box>
            <Box className="countdownItem countdownItemGreen">
                <Typography
                    className='countdownItemNumber'
                    variant="h4"
                >
                    {timeLeft.seconds}
                </Typography>
                <Typography
                    className='countdownItemText'
                >
                    Segundos
                </Typography>
            </Box>
        </Box>
    );
}

export default Countdown;
