import React from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import Slider from 'react-slick';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const BestMen = () => {

    const slides = [
        '/images/best1.png',
        '/images/best2.png',
        '/images/best3.png',
        '/images/best4.png',
    ];

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: {
                        xs: '-20px', // Small devices
                        sm: '-30px', // Medium devices
                    },
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                    fontSize: {
                        xs: '1.5rem', // Increase size on small devices
                        sm: '2rem',
                    },
                }}
            >
                <ArrowForward fontSize="inherit" />
            </IconButton>
        );
    }

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: {
                        xs: '-20px', // Small devices
                        sm: '-30px', // Medium devices
                    },
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                    fontSize: {
                        xs: '1.5rem', // Increase size on small devices
                        sm: '2rem',
                    },
                }}
            >
                <ArrowBack fontSize="inherit" />
            </IconButton>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <Box>
            <Typography className='moderline' variant="h5" gutterBottom>
                Padrinhos
            </Typography>

            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <Grid container spacing={1} key={index}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12} md={12} key={index}>
                                    <Box
                                        component="img"
                                        src={slide}
                                        sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Slider>
            <Typography variant="body1" gutterBottom>
                Tem pessoas que não apenas participam das nossas vidas, mas também às completam. Existem amigos que são mais que amigos, são irmãos.. E têm família que é muito mais que família, são anjos que Deus nos enviou para nos alegrarem e dividirem conosco a cada momento das nossas vidas… não poderíamos dizer sim sem a presença e o testemunho de vocês.
            </Typography>
        </Box>
    );
};

export default BestMen;
