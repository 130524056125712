import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import apiClient from '../../utils/api';

// Define the text item component
const TextItem = ({ text, author }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto',
                width: 'auto',
                padding: '1rem',
            }}
        >
            <Typography
                variant='h5'
                gutterBottom
                sx={{
                    fontFamily: 'Modernline',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    mb: 1,
                }}
            >
                {text}
            </Typography>
            <Typography
                variant='body1'
                color='textSecondary'
                sx={{
                    textAlign: 'center',
                }}
            >
                {author}
            </Typography>
        </Box>
    );
}

const TextSlider = () => {
    const [textItems, setTextItems] = useState([]); // State to store text items
    const [loading, setLoading] = useState(true); // State to handle loading

    // Fetch text items from the API
    useEffect(() => {
        const fetchTextItems = async () => {
            try {
                const response = await apiClient.get('/messages'); // Replace with your API endpoint
                // Transform the response data to match the component's requirements
                const formattedData = response.data.map(item => ({
                    text: item.message,
                    author: item.name,
                }));

                // If no items are returned, use default message
                if (formattedData.length === 0) {
                    setTextItems([{ text: 'Que Deus abençoe o caminho de vcs', author: 'Anônimo' }]);
                } else {
                    setTextItems(formattedData);
                }
            } catch (error) {
                console.error('Error fetching text items:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTextItems();
    }, []); // Empty dependency array means this effect runs once after the initial render

    // Slider settings for infinite scrolling
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one item at a time
        slidesToScroll: 1,
        fade: true, // Enable fade effect
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Speed of autoplay
        cssEase: 'linear', // Smooth fading effect
        arrows: false, // Hide arrows if not needed
        pauseOnHover: true, // Pause on hover
        draggable: true, // Allow dragging
        swipe: true, // Allow swiping
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                overflow: 'hidden',
                padding: '1rem 0',
                borderRadius: '20px',
                backgroundColor: '#f5f5f5', // Optional: background color
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: {
                        xs: '10px', // Adjust top position
                        sm: '0', // Adjust top position
                    },
                    left: {
                        xs: '0', // Adjust left position
                        sm: '60px', // Adjust left position
                    },
                    width: { xs: '30px', sm: '80px' }, // Responsive width
                    height: { xs: '30px', sm: '80px' }, // Responsive height
                    backgroundImage: 'url("/images/Aspas.png")', // Replace with your image URL
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left top',
                    opacity: 0.3, // Optional: Adjust opacity
                    zIndex: 1, // Ensure the image is behind the slider
                }}
            />
            <Slider {...settings}>
                {loading ? (
                    <TextItem text="Loading..." author="" />
                ) : (
                    textItems.map((item, index) => (
                        <TextItem key={index} text={item.text} author={item.author} />
                    ))
                )}
            </Slider>
        </Box>
    );
}

export default TextSlider;
