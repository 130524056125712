import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import BannerSlider from '../components/Slider/BannerSlider';
import Countdown from '../components/Countdown/Countdown';
import IconSlider from '../components/Slider/IconSlider';
import ImageWithText from '../components/ImageWithText/ImageWithText';
import TextSlider from '../components/Slider/TextSlider';

const HomePage = () => {
  const targetDate = new Date('2024-10-12T16:30:00');
  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          color: 'white',
          overflow: 'hidden' // Ensure slider content is contained
        }}
      >
        <BannerSlider />
      </Box>

      {/* Section 1 */}
      <Container
        sx={{
          py: { xs: 3, sm: 4, md: 5 }, // Responsive padding
          textAlign: 'center' // Center content for better responsiveness
        }}
      >
        <Countdown targetDate={targetDate} />
      </Container>

      {/* Section 2 */}
      <Container
        sx={{
          py: { xs: 3, sm: 4, md: 5 }, // Responsive padding
          textAlign: 'center'
        }}
      >
        <IconSlider />
      </Container>

      {/* Section 3 */}
      <Container
        sx={{
          py: { xs: 3, sm: 4, md: 5 }, // Responsive padding
          textAlign: 'center'
        }}
      >
        <TextSlider />
      </Container>


      {/* Section 4 */}
      <Container
        sx={{
          py: { xs: 3, sm: 4, md: 5 },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, side-by-side on larger
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: { xs: 'center', md: 'left' } // Center text on small screens
        }}
      >
        <ImageWithText
          imageSrc="/images/7.png"
          sign={"Rute 1:16-17"}
          text="“Deus mudou o teu caminho até juntares com
o meu e guardou a tua vida separando-a
para mim. Para onde fores, irei. Onde tu
repousares, repousarei. Teu Deus será o meu
Deus. Teu caminho o meu será.”
"
        />
      </Container>
    </div>
  );
};

export default HomePage;
