import React, { useState } from 'react';
import { Box, Grid, Typography, Link, Select, MenuItem, FormControl, InputLabel, Button, Divider } from '@mui/material';

const GiftListModal = () => {
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    const sortGifts = (gifts) => {
        return gifts.sort((a, b) => {
            const priceA = parseFloat(a.price.replace('.', '').replace(',', '.'));
            const priceB = parseFloat(b.price.replace('.', '').replace(',', '.'));

            if (sortOrder === 'asc') {
                return priceA - priceB;
            } else {
                return priceB - priceA;
            }
        });
    };

    const vouchers = [
        {
            price: '100,00',
            link: 'https://www.paypal.com/ncp/payment/7MS5YNW4HF7BJ',
        },
        {
            price: '200,00',
            link: 'https://www.paypal.com/ncp/payment/TF4AHE2QFQKT4',

        },
        {
            price: '300,00',
            link: 'https://www.paypal.com/ncp/payment/U35BBD4PV29A8',

        },
        {
            price: '500,00',
            link: 'https://www.paypal.com/ncp/payment/5H7YAWFPBGDH6',

        }
    ]

    const gifts = [
        {
            name: 'Adega Brastemp 12 Garrafas com painel touch',
            price: '854,05',
            image: '/images/products/product1.jpeg',
            link: 'https://www.paypal.com/ncp/payment/8G674K6WEFH2U',
        },
        {
            name: 'Oster OLIQ610 1400 Full - Liquidificador, 3,2L',
            price: '169,90',
            image: '/images/products/product2.jpeg',
            link: 'https://www.paypal.com/ncp/payment/J3HLNXUWAYH6U',
        },
        {
            name: 'Extrator de Sucos Turbo Premium, Mondial',
            price: '164,70',
            image: '/images/products/product3.jpeg',
            link: 'https://www.paypal.com/ncp/payment/Z7S9TM5VTY7ZC',
        },
        {
            name: 'Edredom Queen 200 Fios 100% Algodão Tom Branco',
            price: '58,99',
            image: '/images/products/product4.jpeg',
            link: 'https://www.paypal.com/ncp/payment/5HBEA8W6UZTGL',
        },
        {
            name: 'Jogo de Banho Gigante 5 Peças Trussardi 100% Algodão Imperiale Branco',
            price: '569,90',
            image: '/images/products/product5.jpeg',
            link: 'https://www.paypal.com/ncp/payment/W2PHJS6MG7H4C',
        },
        {
            name: 'WOLFF Faqueiro de Aço Inox Pisa 101 Peças Com Estojo',
            price: '1303,76',
            image: '/images/products/product7.jpeg',
            link: 'https://www.paypal.com/ncp/payment/2E3DHEH3HGEKQ',
        },
        {
            name: '6 Taças De Cristal Para Champagne 210ml Linha Xtra Bohemia',
            price: '131,00',
            image: '/images/products/product8.jpeg',
            link: 'https://www.paypal.com/ncp/payment/WRE939XZTY9D2',
        },
        {
            name: '6 Taças Vidro Xtra Crystal Bohemia Degustação Cerveja 380 Ml Cor Transparente Xtra Beer',
            price: '159,00',
            image: '/images/products/product9.jpeg',
            link: 'https://www.paypal.com/ncp/payment/VXB6XV7CJAFT6',
        },
        {
            name: 'Aparelho de Jantar Porto Brasil Roma 42 peças',
            price: '799,99',
            image: '/images/products/product10.jpeg',
            link: 'https://www.paypal.com/ncp/payment/V8F3SLYQ4W4ZC',
        },
        {
            name: 'Assadeira Refratária Home Style Tina',
            price: '119,99',
            image: '/images/products/product11.jpeg',
            link: 'https://www.paypal.com/ncp/payment/HWP577LYX5MW6',
        },
        {
            name: 'Cafeteira Espresso Três, Passione',
            price: '386,09',
            image: '/images/products/product13.jpeg',
            link: 'https://www.paypal.com/ncp/payment/YME2LCWUVUBCS',
        },
        {
            name: 'Chaleira Elétrica Digital Oster Inox',
            price: '419,90',
            image: '/images/products/product14.jpeg',
            link: 'https://www.paypal.com/ncp/payment/JJW33WSRBEP7J',
        },
        {
            name: 'Ferro de Passar a Vapor Cerâmica',
            price: '189,90',
            image: '/images/products/product15.jpeg',
            link: 'https://www.paypal.com/ncp/payment/E38LHTY64K3P2',
        },
        {
            name: 'Robô Apirador de Pó Electrolux Home-E Power Experience com Autonomous Technology ERB30',
            price: '849,90',
            image: '/images/products/product16.jpeg',
            link: 'https://www.paypal.com/ncp/payment/JK9LTYC6L4TJN',
        },
        {
            name: 'Samsung Smart TV 43" UHD 4K 43DU7700 - Processador Crystal 4K, Gaming Hub',
            price: '1.939',
            image: '/images/products/product17.jpeg',
            link: 'https://www.paypal.com/ncp/payment/94ECX8CMZWQV6',
        },
        {
            name: 'Jogo de 6 Taças Vinho Tinto Xtra Cristal 460ml',
            price: '165,90',
            image: '/images/products/product18.jpeg',
            link: 'https://www.paypal.com/ncp/payment/6GC5ZT6557JC2',
        },
        {
            name: 'Jogo De 6 Copos 340ml Linha Glass Transparente',
            price: '49,90',
            image: '/images/products/product29.jpeg',
            link: 'https://www.paypal.com/ncp/payment/86XU9UEU4JTXY',
        },
        {
            name: 'Toalha de mesa Retangular Karsten 6 lugares Celebration Veríssimo Granizo',
            price: '135,92',
            image: '/images/products/product19.jpeg',
            link: 'https://www.paypal.com/ncp/payment/3KSPJXF39VGQY',
        },
        {
            name: 'WOLFF Garrafa Térmica de Plástico com Cabo de Madeira Nórdica 1L',
            price: '81,73',
            image: '/images/products/product20.jpeg',
            link: 'https://www.paypal.com/ncp/payment/679VYS37CDZRE',
        },
        {
            name: 'Panela de Pressão Rochedo Smart Antiaderente Fechamento Externo Preta 4,5L',
            price: '159,90',
            image: '/images/products/product21.jpeg',
            link: 'https://www.paypal.com/ncp/payment/CJNP85LQZZ7J6',
        },
        {
            name: 'Jogo de Panelas Tramontina Revestimento Cerâmico de Alumínio Cinza 5 Peças Glenz',
            price: '678,90',
            image: '/images/products/product22.jpeg',
            link: 'https://www.paypal.com/ncp/payment/6TCSXBWZT4JPG',
        },
        {
            name: 'Forno Elétrico de Embutir EOS 47L Porta de Vidro Espelhado',
            price: '989,00',
            image: '/images/products/product23.jpeg',
            link: 'https://www.paypal.com/ncp/payment/6V2SJWPP565R8',
        },
        {
            name: 'Fogão Cooktop Fischer 5Q FIT LINE Gás Mesa Vidro',
            price: '625,12',
            image: '/images/products/product24.jpeg',
            link: 'https://www.paypal.com/ncp/payment/GCY9XY9QNZTTJ',
        },
        {
            name: 'Conjunto de Refratários Celebrity, Retangular, 3 Peças, Incolor, Marinex',
            price: '67,15',
            image: '/images/products/product25.jpeg',
            link: 'https://www.paypal.com/ncp/payment/QB9FS8RNDBCSG',
        },
        {
            name: 'Jarra de Vidro Chicago Transparente 1,2L - Lehaví',
            price: '38,90',
            image: '/images/products/product26.jpeg',
            link: 'https://www.paypal.com/ncp/payment/WG6KBU95ARYRY',
        },
        {
            name: 'Samsung Smart TV 65" UHD 4K 65DU7700 - Processador Crystal 4K, Gaming Hub',
            price: '3229,05',
            image: '/images/products/product27.jpeg',
            link: 'https://www.paypal.com/ncp/payment/DSZE8SDS8CQ2N',
        }
    ];

    const sortedGifts = sortGifts([...gifts]);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <Box sx={{ overflowY: 'auto', padding: '16px' }}>
            <Typography
                className='moderline'
                variant="h5"
                gutterBottom
                sx={{ marginTop: '5px' }}
            >
                Lista de Presentes
            </Typography>

            {/* Vouchers Section */}
            <Grid container spacing={2} sx={{ marginTop: '0px' }}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ marginTop: '10px' }}>
                        Uma ajudinha para a lua de mel.
                    </Typography>
                </Grid>
                {vouchers.map((voucher, index) => (
                    <Grid item xs={6} sm={6} md={3} key={index}
                        sx={{
                            width: '100%', display: 'flex', justifyContent: 'center'
                        }}>
                        <Box sx={{
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            padding: {
                                xs: '8px',
                                sm: '8px',
                                md: '8px',
                            },
                            textAlign: 'center',
                            transition: 'transform 0.2s',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <Typography variant="body1" sx={{ marginTop: '5px', textAlign: 'center' }}>
                                R$ {voucher.price}
                            </Typography>
                            <Button
                                sx={{ marginTop: '10px', padding: '0.5rem !important' }}
                                variant="contained"
                                color="primary"
                                className='formButton'
                                href={voucher.link}
                                target="_blank"
                                rel="noopener"
                            >
                                Presentear
                            </Button>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Divider sx={{ margin: '20px 0' }} />

            {/* Gifts Section */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px', marginTop: '30px' }}>
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                    <InputLabel id="sort-label">Ordernar por Preço</InputLabel>
                    <Select
                        labelId="sort-label"
                        value={sortOrder}
                        onChange={handleSortChange}
                        label="Ordernar por Preço"
                    >
                        <MenuItem value="asc">Menor para Maior</MenuItem>
                        <MenuItem value="desc">Maior para Menor</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Grid container spacing={2} sx={{ marginTop: '0px' }}>
                {sortedGifts.map((gift, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{
                        width: '100%', height: {
                            xs: '320px',
                            md: '340px',
                        }, display: 'flex', justifyContent: 'center'
                    }}>
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                padding: {
                                    xs: '8px',
                                    sm: '12px',
                                    md: '16px',
                                },
                                textAlign: 'center',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                src={gift.image}
                                alt={gift.name}
                                sx={{
                                    width: '150px',
                                    height: '150px',
                                    borderRadius: '8px',
                                    maxWidth: {
                                        xs: '100%',
                                        sm: '80%',
                                        md: '100%',
                                    },
                                    objectFit: 'cover',
                                }}
                            />
                            <Typography variant="body2" sx={{ marginTop: '10px', textAlign: 'center' }}>
                                {truncateText(gift.name, 30)}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: '5px', textAlign: 'center' }}>
                                R$ {gift.price}
                            </Typography>
                            <Button
                                variant="contained"
                                className='formButton'
                                sx={{ marginTop: '10px' }}
                                href={gift.link}
                                target="_blank"
                                rel="noopener"
                            >
                                Presentear
                            </Button>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};


export default GiftListModal;
