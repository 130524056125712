import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Grid } from '@mui/material';
import MailIcon from '../Icons/MailIcon';
import { toast } from 'react-toastify';
import apiClient from '../../utils/api';

const LeaveANote = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        if (formData.email === '' || formData.name === '' || formData.message === '') {
            toast.error('Preencha todos os campos!');
            return;
        }

        try {
            await apiClient.post('/messages', formData);
            toast.success('Mensagem enviada com sucesso!');
            onSubmit();
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Erro ao enviar mensagem.');
        }


    };

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography
                variant="h5"
                align="left"
                className='moderline'
            >
                Deixe um recadinho
            </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <MailIcon onlyIcon={true} height='200px' />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 500,
                    margin: 'auto',
                    padding: 2,
                    gap: 2
                }}
            >
                <TextField
                    label="Nome"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                />
                <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    type="email"
                    required
                />
                <TextField
                    label="Mensagem"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    required
                />
                <Button
                    type="submit"
                    className='formButton'
                    sx={{ marginTop: 2 }}
                >
                    Enviar
                </Button>
            </Box>
        </Grid>
    </Grid>
};

export default LeaveANote;