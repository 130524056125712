import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';

import { ToastContainer } from 'react-toastify';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import HomePage from './pages/HomePage';
import GuestsList from './pages/GuestsList';

function App() {
  document.title = 'Carlos & Carlota'

  return (
    <div className="App">
      <div className='container'>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/guests-5a2fce17-ebd2-444f-a866-53b67df85ef6" element={<GuestsList />} />
          </Routes>
        </Router>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
