import React from 'react';
import Slider from 'react-slick';
import { Typography, IconButton, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const MakingOfModal = () => {
    const slides = [
        { image: '/images/1.jpeg' },
        { image: '/images/2.jpeg' },
        { image: '/images/3.jpeg' },
        { image: '/images/4.jpeg' },
        { image: '/images/5.jpeg' },
        { image: '/images/6.jpeg' },
        { image: '/images/8.jpg' },
        { image: '/images/9.jpg' },
        { image: '/images/10.jpg' },
        { image: '/images/11.jpg' },
        { image: '/images/13.jpg' },
        { image: '/images/14.jpg' },
        { image: '/images/15.jpg' },
        { image: '/images/16.jpg' },
        { image: '/images/17.jpg' },
        { image: '/images/18.jpeg' },
        { image: '/images/21.jpeg' },

    ];

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: {
                        xs: '-20px', // Small devices
                        sm: '-30px', // Medium devices
                    },
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                    fontSize: {
                        xs: '1.5rem', // Larger arrow size on small devices
                        sm: '2rem',
                    },
                }}
            >
                <ArrowForward fontSize="inherit" />
            </IconButton>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: {
                        xs: '-20px', // Small devices
                        sm: '-30px', // Medium devices
                    },
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                    fontSize: {
                        xs: '1.5rem', // Larger arrow size on small devices
                        sm: '2rem',
                    },
                }}
            >
                <ArrowBack fontSize="inherit" />
            </IconButton>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <Box>
            <Typography className='moderline' variant="h5" component="div">
                Making Of
            </Typography>
            <Box sx={{ marginTop: '3rem', backgroundColor: '#fff', padding: '1rem 2rem' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: '80px', // Small devices
                            sm: '130px', // Medium devices
                        },
                        left: {
                            xs: '0px', // Small devices
                            sm: '40px', // Medium devices   
                        },
                        width: { xs: '100px', sm: '150px', md: '100' }, // Responsive size
                        height: { xs: '100px', sm: '150px', md: '100px' }, // Responsive size
                        backgroundImage: 'url("/images/Aspas.png")', // Replace with your image URL
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left top',
                        opacity: 0.9, // Adjust the opacity
                        zIndex: 1, // Ensure the image is behind the content
                    }}
                />
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '1rem',
                            }}
                        >
                            <img
                                src={slide.image}
                                alt={`Making Of ${index + 1}`}
                                style={{ maxWidth: '100%', height: 'auto' }} // Ensure the image scales properly
                            />
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
}

export default MakingOfModal;
