import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, TextField, IconButton, Autocomplete, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import { toast } from 'react-toastify';
import apiClient from '../../utils/api'; // Import the Axios instance

const RSVPModal = ({ onSubmit }) => {
    const [inputFields, setInputFields] = useState([{ id: 1, value: '' }]);
    const [phone, setPhone] = useState('');
    const [musicSuggestion, setMusicSuggestion] = useState('');
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [loading, setLoading] = useState(true); // Start with loading true

    useEffect(() => {
        // Fetch autocomplete options once on component mount
        const fetchOptions = async () => {
            try {
                const response = await apiClient.get('/guests');
                // Assuming the response is in the format [{ id, name, surname }]
                const formattedOptions = response.data.map(item => ({
                    id: item.id,
                    label: `${item.name} ${item.surname}`,
                    value: item.id,
                }));
                setAutocompleteOptions(formattedOptions);
            } catch (error) {
                console.error('Error fetching autocomplete options:', error);
                toast.error('Error fetching autocomplete options.');
            } finally {
                setLoading(false);
            }
        };

        fetchOptions();
    }, []);

    const handleAddField = () => {
        setInputFields([...inputFields, { id: inputFields.length + 1, value: '' }]);
    };

    const handleInputChange = (fieldId, newValue) => {
        setInputFields(prevFields => prevFields.map(field => {
            if (field.id === fieldId) {
                return { ...field, value: newValue ? newValue.value : '' };
            }
            return field;
        }));
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleMusicSuggestionChange = (event) => {
        setMusicSuggestion(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create payload
        const guestIds = inputFields.map(field => field.value).filter(value => value); // Filter out empty values
        const payload = {
            guestIds,
            phone,
            musicSuggestion
        };

        if (guestIds.length === 0 || !phone) {
            toast.error('Por favor, preencha todos os campos.');
            return;
        }

        console.log("Form submitted with the following data:");
        console.log(payload);

        try {
            await apiClient.post('/guests/confirmation', payload);
            toast.success('Confirmação enviada com sucesso!');
            onSubmit(); // Close the modal
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Erro ao enviar confirmação.');
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2,
                margin: '0 auto',
            }}
        >
            <Typography className='moderline' variant="h5" sx={{ marginBottom: '2rem' }}>
                Confirmar Presença
            </Typography>
            <Box sx={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '3%' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: '80px', // Small devices
                            sm: '130px', // Medium devices
                        },
                        left: {
                            xs: '0px', // Small devices
                            sm: '40px', // Medium devices   
                        },
                        width: { xs: '90px', sm: '150px', md: '100' }, // Responsive size
                        height: { xs: '90px', sm: '150px', md: '100px' }, // Responsive size
                        backgroundImage: 'url("/images/Aspas.png")', // Replace with your image URL
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left top',
                        opacity: 0.3, // Adjust the opacity
                        zIndex: 1, // Ensure the image is behind the content
                    }}
                />
                {inputFields.map((field) => (
                    <Box
                        key={field.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                            marginBottom: '1rem', // Add space between the input fields
                        }}
                    >
                        <Autocomplete
                            options={autocompleteOptions.filter(option => !inputFields.map(f => f.value).includes(option.value))}
                            getOptionLabel={(option) => option.label}
                            value={autocompleteOptions.find(option => option.value === field.value) || null}
                            onChange={(event, newValue) => handleInputChange(field.id, newValue)}
                            fullWidth
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Nome e Sobrenome"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading && <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <IconButton onClick={handleAddField} color="primary">
                            <Add />
                        </IconButton>
                    </Box>
                ))}
                <TextField
                    label="WhatsApp"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: '1rem' }}  // Add space before this field
                    value={phone}
                    onChange={handlePhoneChange}
                />
                <TextField
                    label="Sugira uma música que você gostaria de ouvir"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: '1rem' }}  // Add space before this field
                    value={musicSuggestion}
                    onChange={handleMusicSuggestionChange}
                />
                <Button type="submit" className='formButton'>
                    Confirmar
                </Button>
            </Box>
        </Box>
    );
};

export default RSVPModal;
