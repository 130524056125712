import { Box, Typography } from '@mui/material';

const ImageWithText = ({ imageSrc, text, sign }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column', // Stack vertically on extra-small screens
                    sm: 'row'     // Arrange side-by-side on small screens and up
                },
                alignItems: 'center', // Center items vertically
                width: '100%', // Full width of the container
                padding: 2, // Optional padding for spacing
                gap: 2, // Add space between image and text
            }}
        >
            <Box
                component="img"
                src={imageSrc}
                alt="Image"
                sx={{
                    flex: 1, // Allow image to take up available space
                    width: {
                        xs: '100%', // Full width on extra-small screens
                        sm: '50%'   // Half width on small screens and up
                    },
                    height: 'auto', // Maintain aspect ratio
                    objectFit: 'cover', // Ensure image covers the area without distortion
                }}
            />
            <Box
                sx={{
                    flex: 1, // Allow text to take up available space
                    width: {
                        xs: '100%', // Full width on extra-small screens
                        sm: '50%'   // Half width on small screens and up
                    },
                    padding: {
                        xs: 1,  // Less padding on extra-small screens
                        sm: 2,  // More padding on small screens and up
                    },
                    textAlign: {
                        xs: 'center', // Center text on extra-small screens
                        sm: 'left'    // Left-align text on small screens and up
                    }
                }}
            >
                <Typography variant="h5">
                    {text}
                    <br />
                    {sign}
                </Typography>
            </Box>
        </Box>
    );
}

export default ImageWithText;
