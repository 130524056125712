import { Typography, Box } from '@mui/material';
import Slider from 'react-slick';

const BannerSlider = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        fade: true,
    };

    const slides = [
        { image: '/images/1.jpeg', title: 'Slide 1', content: 'Content for Slide 1' },
        { image: '/images/2.jpeg', title: 'Slide 2', content: 'Content for Slide 2' },
        { image: '/images/3.jpeg', title: 'Slide 2', content: 'Content for Slide 2' },
    ];

    return (
        <div>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: '100%',
                            height: { xs: '400px', sm: '400px', md: '500px', lg: '700px' }, // Responsive height
                            backgroundImage: `url(${slide.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                    </Box>
                ))}
            </Slider>
        </div>
    );
};

export default BannerSlider;
