import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const LocationModal = () => {

    const position = [-19.863329595170235, -43.9777004746496]; // Coordinates for the location of the wedding
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    align="left"
                    className='moderline'
                >
                    Evento
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: '2rem' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: '80px', // Small devices
                            sm: '130px', // Medium devices
                        },
                        left: {
                            xs: '0px', // Small devices
                            sm: '40px', // Medium devices   
                        },
                        width: { xs: '80px', sm: '150px', md: '100' }, // Responsive size
                        height: { xs: '80px', sm: '150px', md: '100px' }, // Responsive size
                        backgroundImage: 'url("/images/Aspas.png")', // Replace with your image URL
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left top',
                        opacity: 0.2, // Adjust the opacity
                        zIndex: 1, // Ensure the image is behind the content
                    }}
                />
                <Typography className='orange' variant="h5">
                    Traje
                </Typography>
                <Typography variant="body1">
                    Para o nosso casamento, solicitamos que todos os convidados usem traje esporte fino. Este estilo de vestimenta e elegante e apropriado para a ocasião.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" className='orange'>
                    Localização
                </Typography>
                <Box
                    sx={{
                        height: '300px',
                        maxHeight: '300px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}
                >
                    <MapContainer
                        center={position}
                        zoom={15}
                        style={{ height: '100%', width: '100%' }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        <Marker position={position}>
                            <Popup>
                                Av. Dom Orione, 200 - São Luiz, Belo Horizonte - MG, 31310-020
                            </Popup>
                        </Marker>
                    </MapContainer>
                </Box>
            </Grid>
        </Grid>
    );

};

export default LocationModal;