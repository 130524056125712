import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const ModalContent = ({ open, onClose, content }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                        xs: '95%', // Full width with a small margin on mobile devices
                        sm: '90%', // Slightly less wide on tablets
                        md: 600 // Fixed width on larger screens (desktops)
                    },
                    bgcolor: 'background.paper',
                    backgroundColor: '#ededed',
                    boxShadow: 24,
                    p: {
                        xs: 2, // Less padding on mobile devices
                        sm: 4, // Slightly more padding on tablets
                        md: 8  // Original padding on larger screens
                    },
                    overflowY: 'auto',
                    maxHeight: {
                        xs: 'calc(100vh - 4rem)', // 2rem margin on top and bottom
                        sm: 'calc(90vh - 4rem)',  // Slightly less on tablets
                        md: 'calc(80vh - 6rem)'  // 3rem margin on top and bottom on larger screens
                    },
                    mt: {
                        xs: 2,  // 2rem margin on top on mobile devices
                        sm: 2,  // 2rem margin on top on tablets
                        md: 3   // 3rem margin on top on larger screens
                    },
                    mb: {
                        xs: 2,  // 2rem margin on bottom on mobile devices
                        sm: 2,  // 2rem margin on bottom on tablets
                        md: 3   // 3rem margin on bottom on larger screens
                    }
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <Close />
                </IconButton>
                <Box>
                    {content}
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalContent;
